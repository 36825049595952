import React, {useState} from 'react'
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Box, Flex, Spacer, Heading, Stack, Text, Grid, GridItem, Button, Input, FormControl, Badge } from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    // Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Select,
    Center,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Image
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import excel from '../../assets/excel.png'
import csv from '../../assets/csv.png'
import xml from '../../assets/xml.png'
import useAuth from '../../hooks/useAuth';

function BodyCard() {

    const { user } = useAuth();

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
        _export.current.save();
        }
    };

    const [totalAmount, setTotalAmount] = React.useState(0);
    const [transactions, setTransactions] = React.useState([]);

    const [cookies, setCookie] = useCookies(['swahilies_pay_user']);

    let today = new Date().toISOString().slice(0, 10)

    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);

    const handleInputChange = (e) => setStartDate(e.target.value)
    const handleInputChangeTwo = (e) => setEndDate(e.target.value)

    const user_cookie = cookies.swahilies_user_details.user_details;

    const merchantTransactions = (start, end) => {
        axios
        .post('https://swahiliesapi.invict.site/Api', {
            code: 111,
            api: 120,
            data: {
                supplier_id: user_cookie.user_id, //'6029786d21a84f7ead70e3c00510cda4'
                start: start,
                end: end
            }
        })
        .then((res) => {
            let transactions = res.data;
            setTransactions(transactions.transactions);
            setTotalAmount(transactions.total);
        })
        .catch((e) => {
            console.log(e);
        });
    };

    React.useEffect(() => {
        merchantTransactions(today, today);
    }, []);

    const onSubmit = async () => {
        merchantTransactions(startDate, endDate);
    };

    return (
        <>
            <Box w='100%' p={4} pr={{ base: 3, md: 10, xl: 20}} pl={{ base: 3, md: 10, xl: 20}} mb={{ base: 3, md: 10, xl: 20}}>
                <Flex gap={4} flexDirection={{ base: 'column', md: 'row', xl: 'row'}}>
                    <Box p='4' h={{ base: 200, md: 210, xl: 200}} w={{ base: '100%', md: '50%', xl: '50%'}} bg='white' rounded='md' boxShadow='sm'>
                        <Stack pl={{ base: 1, md: 2, xl: 10}} pb={{ base: 1, md: 2, xl: 10}} pt={{ base: 1, md: 2, xl: 10}}>
                            <Heading as='h6' size='xs' fontFamily='Inter, sans-serif'>
                                Transactions Activity
                            </Heading>
                            <Text fontSize='xs' color='#9A9A9A' fontFamily='Inter, sans-serif' >Showing your transactions</Text>
                            <Grid templateColumns='repeat(5, 1fr)' gap={6}>
                                <FormControl isRequired>
                                    <Flex gap={{ base: 1, md: 3, xl: 4 }} flexDirection={{ base: 'column', md: 'column', xl: 'row' }} >
                                        <Flex gap={4} flexDirection='row' >
                                            <GridItem>
                                                <Text fontSize='xs' color='#9A9A9A' fontFamily='Inter, sans-serif' >From:</Text>
                                                <Input
                                                    type='date'
                                                    value={startDate}
                                                    onChange={handleInputChange}
                                                    variant='filled'
                                                    size={{ base: 'sm', md: 'sm', xl: 'sm' }}
                                                    htmlSize={8} mt={1}
                                                    fontFamily='Inter, sans-serif'
                                                />
                                            </GridItem>
                                            <GridItem>
                                                <Text fontSize='xs' color='#9A9A9A' fontFamily='Inter, sans-serif' >To:</Text>
                                                <Input
                                                    type='date'
                                                    value={endDate}
                                                    onChange={handleInputChangeTwo}
                                                    variant='filled'
                                                    size={{ base: 'sm', md: 'sm', xl: 'sm' }}
                                                    htmlSize={4}
                                                    mt={1}
                                                    fontFamily='Inter, sans-serif'
                                                />
                                            </GridItem>
                                        </Flex>
                                        <GridItem>
                                            <Button
                                                bg='#F6A414'
                                                variant='solid'
                                                size='md'
                                                height={{ base: '30px', md: '35px', xl: '35px'}}
                                                width='125px'
                                                color='white'
                                                mt={5}
                                                // fontFamily='Inter, sans-serif'
                                                onClick={onSubmit}
                                            >
                                                Search
                                            </Button>
                                        </GridItem>
                                    </Flex>
                                </FormControl>
                            </Grid>
                        </Stack>
                    </Box>
                    <Spacer />
                    <Box p='4' h={250} w={{ base: '100%', md: '50%', xl: '50%'}} bg='white' rounded='md' boxShadow='sm'>
                        <Stack p={{ base: 1, md: 2, xl: 2}} spacing={4}>
                            <Stack spacing={0}>
                                <Text as='b' color='#9A9A9A' fontSize={13} fontFamily='Inter, sans-serif' >Vendor Number</Text>
                                <Heading as='h6' size='xs' fontFamily='Inter, sans-serif'>
                                    {user_cookie.supplier_number}
                                </Heading>
                            </Stack>
                            <Stack spacing={0}>
                                <Text as='b' color='#9A9A9A' fontSize={13} fontFamily='Inter, sans-serif' >Merchant Name</Text>
                                <Heading as='h6' size='xs' fontFamily='Inter, sans-serif'>
                                    {user_cookie.supplier_name}
                                </Heading>
                            </Stack>
                            <Stack spacing={0}>
                                <Text as='b' color='#9A9A9A' fontSize={13} fontFamily='Inter, sans-serif' >Phone</Text>
                                <Heading as='h6' size='xs' fontFamily='Inter, sans-serif'>
                                    +{user_cookie.phone_number}
                                </Heading>
                            </Stack>
                            <Flex>
                                <Stack spacing={0}>
                                    <Text as='b' color='#9A9A9A' fontSize={13} fontFamily='Inter, sans-serif' >Transactions</Text>
                                    <Heading as='h6' size='xs' fontFamily='Inter, sans-serif'>
                                        TZS {(totalAmount ? totalAmount : 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                    </Heading>
                                </Stack>
                                <Spacer />
                                <Stack spacing={0}>
                                    <Text as='b' color='#9A9A9A' fontSize={13} fontFamily='Inter, sans-serif' >Charges</Text>
                                    <Heading as='h6' size='xs' fontFamily='Inter, sans-serif'>
                                        TZS {(totalAmount * 0.03).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                    </Heading>
                                </Stack>
                            </Flex>
                        </Stack>
                    </Box>
                </Flex>
            </Box>
            {/* Body table goes here, i know its a bad idea but make it work for now */}
            <TableContainer w='100%' p={4} pr={{ base: 3, md: 10, xl: 20}} pl={{ base: 3, md: 10, xl: 20}} mb={{ base: 3, md: 10, xl: 10}}>
                <Table size='sm' bg='white' rounded='md' fontFamily='Inter, sans-serif' >
                    <TableCaption placement='top' bg='white' rounded='md'>
                        <Flex p={4}>
                            <Input placeholder='Search' fontFamily='Inter, sans-serif' variant='filled' size='sm' width={{ base: 100, md: 200, xl: 300}} />
                            <Spacer />
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    rightIcon={<ChevronDownIcon />}
                                    bg='#F6A414'
                                    variant='solid'
                                    size='md'
                                    height='30px'
                                    width='145px'
                                    color='black'
                                >
                                    Export Data
                                </MenuButton>
                                <MenuList>
                                    {/* <MenuItem minH='48px'>
                                        <Image
                                            boxSize='1.5rem'
                                            src={csv}
                                            alt='Fluffybuns the destroyer'
                                            mr='12px'
                                        />
                                        <span>CSV</span>
                                    </MenuItem> */}
                                    <MenuItem minH='48px'>
                                        <Image
                                            boxSize='1.5rem'
                                            src={excel}
                                            alt='Fluffybuns the destroyer'
                                            mr='12px'
                                        />
                                        <span>XLS</span>
                                    </MenuItem>
                                    {/* <MenuItem minH='48px'>
                                        <Image
                                            boxSize='1.5rem'
                                            src={xml}
                                            alt='Fluffybuns the destroyer'
                                            mr='12px'
                                        />
                                        <span>XML</span>
                                    </MenuItem> */}
                                </MenuList>
                            </Menu>
                        </Flex>
                        <Flex pl={4} pt={4} pb={4}>
                            <Center>
                                <Text fontFamily='Inter, sans-serif' fontSize='xs' pr={1} color='#9A9A9A' >ROWS TO SHOW:</Text>
                                <Select
                                    placeholder='10'
                                    color='#000000'
                                    size='xs'
                                    width='60px'
                                    variant='filled'
                                    fontFamily='Inter, sans-serif'
                                >
                                    <option fontFamily='Inter, sans-serif' value='option1'>50</option>
                                    <option value='option2'>100</option>
                                    <option value='option3'>All</option>
                                </Select>
                            </Center>
                        </Flex>
                    </TableCaption>
                    <Thead bg='#F6A414' rounded='md'>
                    <Tr>
                        <Th fontFamily='Inter, sans-serif'>Date</Th>
                        <Th fontFamily='Inter, sans-serif'>Trans ID</Th>
                        <Th fontFamily='Inter, sans-serif'>Status</Th>
                        {/* <Th fontFamily='Inter, sans-serif'>Channel</Th> */}
                        <Th fontFamily='Inter, sans-serif'>Phone number</Th>
                        <Th fontFamily='Inter, sans-serif'>Amount (TZS)</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                        {transactions ? transactions.length > 0 ? transactions.map((row, i) => (
                            <Tr key={i}>
                                <Td>{new Date(row.registered).toLocaleString()}</Td>
                                <Td>{row.order_id}</Td>
                                <Td>
                                    <Badge colorScheme={ row.status === 'paid' ? 'green' : 'gray'}>{row.status}</Badge>
                                </Td>
                                {/* <Td>M-Pesa</Td> */}
                                <Td>+{row.phone_number}</Td>
                                <Td>{(row.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Td>
                            </Tr>
                        ))
                            :
                            <Tr>
                                <Td colSpan={5}>No transaction yet</Td>
                            </Tr>
                            :
                            <Tr>
                                <Td rowSpan={5}>Loading.....</Td>
                            </Tr>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}

export default BodyCard
