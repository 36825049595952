import React, {useState} from 'react'
import { useNavigate} from 'react-router-dom'
import {
    FormControl,
    Stack,
    Input,
    Text,
    Box,
    Center,
    Button,
    Image,
    Flex,
    Heading,
    useToast 
} from '@chakra-ui/react'
import './index.css'
import Logo from '../../assets/logo.png'
import useAuth from '../../hooks/useAuth';

function Login() {

    const navigate = useNavigate();
    const toast = useToast()

    const [phone, setPhone] = useState('');
    const { login } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => setPhone(e.target.value)

    const navigateOtp = () => {
    // 👇️ navigate to /
    navigate('/otp');
    };

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            const message = await login(phone);
            if (message === 'success') {
                setIsLoading(false);
                navigateOtp()
            } else {
                setIsLoading(false);
                toast({
                    title: 'Could not login',
                    description: "Merchant Number Does Not Exist",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div className='main'>
            <Center>
                <Box bg='white' rounded='md' w={{ base: 350, md: 480, xl: 480}} h={480} mt={130} pl={20} pt={5} pr={20} >
                    <Center>
                        <Stack spacing={2} alignItems='center'>
                            <Flex>
                                <Center>
                                    <Image src={Logo} alt='Swahilies' h={70} />
                                    <Heading as='h2' size='xl' fontFamily='Inter, sans-serif'>Pay</Heading>
                                </Center>
                            </Flex>
                            <Text fontSize='sm' color='#9A9A9A' fontFamily='Inter, sans-serif' >Please Login with your Swahilies Pay Credentials</Text>
                            <FormControl isRequired>
                                <Input
                                    type='text'
                                    value={phone}
                                    onChange={handleInputChange}
                                    placeholder='Your Vendor Phone Number'
                                    mb={6}
                                    mt={8}
                                    width='325px'
                                    fontFamily='Inter, sans-serif'
                                />
                                {
                                    isLoading ? (
                                        <Button
                                            bg='#F6A414'
                                            variant='solid'
                                            size='md'
                                            height='35px'
                                            width='325px'
                                            color='white'
                                            mt={2}
                                            mb={6}
                                            // onClick={onSubmit}
                                            isLoading
                                            loadingText='Submitting'
                                        >Login
                                        </Button>
                                    ) :
                                    (
                                    <Button
                                        bg='#F6A414'
                                        variant='solid'
                                        size='md'
                                        height='35px'
                                        width='325px'
                                        color='white'
                                        mt={2}
                                        mb={6}
                                        onClick={onSubmit}
                                    >Login
                                    </Button>
                                    )
                                }
                                    
                            </FormControl>
                            <Text as='b' color='#9A9A9A' fontFamily='Inter, sans-serif' >Support Center (24hrs)</Text>
                            <Text fontSize='sm' color='#9A9A9A' fontFamily='Inter, sans-serif'>Call / Whatsapp: +255 682 411 725</Text>
                            <Text fontSize='sm' color='#9A9A9A' fontFamily='Inter, sans-serif'>Email: info@swahilies.com</Text>
                        </Stack>
                    </Center>
                </Box>
            </Center>
        </div>
        
    )
}

export default Login