import { createContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { useCookies } from 'react-cookie'
// routes

// ----------------------------------------------------------------------

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;
        return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;

        return {
        ...state,
        isAuthenticated: true,
        user,
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload;

        return {
        ...state,
        isAuthenticated: true,
        user,
        };
    },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialState,
    method: 'jwt',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node,
};

function AuthProvider({ children }) {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [newPhone, setNewPhone] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies(['swahilies_pay_user']);
    

    useEffect(() => {
        const initialize = async () => {
        try {
            const accessToken = window.localStorage.getItem('swahilies_userDetails');
            
            if (accessToken) {
                setSession(accessToken);

                const user_data = JSON.parse(accessToken);

                const user = {
                    name: user_data.user_details.supplier_name,
                    user_id: user_data.user_details.user_id,
                    phone_number: user_data.user_details.phone_number,
                    supplier_number: user_data.user_details.supplier_number
                };

                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                    isAuthenticated: true,
                    user,
                    },
                });
                } else {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                    isAuthenticated: false,
                    user: null,
                    },
                });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
                });
            }
    };

        initialize();
    }, []);

    const changeNumber = (number) => {
        if (number.length === 10) {
        setNewPhone('255' + number.substr(1, 9));
        return '255' + number.substr(1, 9);
        } else if (number.length === 12) {
        setNewPhone(number);
        return number;
        } else if (number.length === 13) {
        setNewPhone(number.substr(1, 12));
        return number.substr(1, 12);
        } else {
        setNewPhone(number);
        return number;
        }
    };

    const login = async (phone) => {
        try {
        let data = await axios.post('https://swahiliesapi.invict.site/Api', {
            code: 100,
            api: 120,
            data: { phone_number: changeNumber(phone)},
        });
        let message = ''
        if (data.data.code === 200) {
            localStorage.setItem('user_phone', changeNumber(phone));
            message = 'success'
        } else {
            message = 'fail'
        }

        return message;
        } catch (e) {
        console.log(e);
        }
    };

    const otp = async (otp) => {
        const phone_number = window.localStorage.getItem('user_phone');
        try {
        let message = ''
        const data = await axios.post('https://swahiliesapi.invict.site/Api', {
            code: 101,
            api: 120,
            data: { phone_number: phone_number, otp: otp },
        });
        if (data.data.code === 200) {
            const { user_id } = data.data.user_details.user_id;

            setSession(user_id);
            localStorage.setItem('swahilies_userDetails', JSON.stringify(data.data));
            setCookie('swahilies_user_details', JSON.stringify(data.data), { path: '/' });
            dispatch({
                type: 'LOGIN',
                payload: {
                    user: data.data,
                },
            });
            navigate('/home');
            message = 'success'
        } else {
            message = 'fail'
        }

        return message;
        } catch (e) {
        console.log(e);
        }
    };

    const logout = async () => {
        setSession(null);
        removeCookie('swahilies_user_details', { path: '/' })
        dispatch({ type: 'LOGOUT' });
    };

    return (
        <AuthContext.Provider
        value={{
            ...state,
            method: 'jwt',
            login,
            logout,
            otp,
        }}
        >
        {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
