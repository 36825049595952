import React from 'react'
import { useCookies } from 'react-cookie';
import {
    Box,
    Flex,
    Spacer,
    Text,
    Center,
    Icon,
    Avatar,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react'
import { FiHome, FiUser, FiLogOut, FiLock } from "react-icons/fi";
import { ChevronDownIcon } from '@chakra-ui/icons'
import useAuth from '../../hooks/useAuth';

function Header() {

    const { user, logout } = useAuth();
    const [cookies, setCookie] = useCookies(['swahilies_pay_user']);

    const user_cookie = cookies.swahilies_user_details.user_details;

    return (
        <Box bg='#F6A414' w='100%' p={4} color='white' pr={{ base: 3, md: 10, xl: 20}} pl={{ base: 3, md: 10, xl: 20}}mb={10}>
            <Flex>
                <Flex>
                    <Center >
                        <Icon as={FiHome} color='black' pr={1} w={6} h={6} />
                        <Text as='b' color='black' fontFamily='Inter, sans-serif'>Dashboard</Text>
                    </Center>
                </Flex>
                <Spacer />
                <Flex>
                    <Center>
                        <Avatar bg='white' w={25} h={25} icon={<Icon as={FiUser} color='black'  />} />
                        <Text pl={2} pr={1} as='b' fontFamily='Inter, sans-serif' >{user_cookie.supplier_name}</Text>
                        <Menu>
                            <MenuButton
                                as={ChevronDownIcon}
                                aria-label='Options'
                                icon={<ChevronDownIcon color="black" />}
                                variant='outline'
                            />
                            <MenuList>
                                <MenuItem icon={<Icon as={FiLock} color='black' pr={1} w={4} h={4} />} color='black' fontFamily='Inter, sans-serif' fontSize={13}>
                                    Change Password
                                </MenuItem>
                                <MenuItem onClick={logout} icon={<Icon as={FiLogOut} color='black' pr={1} w={4} h={4} />} color='black' fontFamily='Inter, sans-serif' fontSize={13}>
                                    Logout
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Center>
                </Flex>
            </Flex>
        </Box>
    )
}

export default Header