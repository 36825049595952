import React, {useState} from 'react'
import {
    FormControl,
    Stack,
    HStack,
    Text,
    Box,
    Center,
    Button,
    Image,
    Flex,
    Heading,
    PinInput,
    PinInputField,
    useToast
} from '@chakra-ui/react'
import './index.css'
import Logo from '../../assets/logo.png'
import useAuth from '../../hooks/useAuth';

function Otp() {

    const toast = useToast()

    const phone_number = window.localStorage.getItem('user_phone');

    const [otp1, setOtp1] = useState('');
    const [otp2, setOtp2] = useState('');
    const [otp3, setOtp3] = useState('');
    const [otp4, setOtp4] = useState('');
    const { otp } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange1 = (e) => {
        setOtp1(e.target.value.toString())
    }
    const handleInputChange2 = (e) => {
        setOtp2(e.target.value.toString())
    } 
    const handleInputChange3 = (e) => {
        setOtp3(e.target.value.toString())
    } 
    const handleInputChange4 = (e) => {
        setOtp4(e.target.value.toString())
    } 

    const onSubmit = async () => {
        let otps = otp1 + otp2 + otp3 + otp4;
        try {
            setIsLoading(true);
            const message = await otp(parseInt(otps));
            if (message === 'success') {
                setIsLoading(false);
            } else {
                setIsLoading(false);
                toast({
                    title: 'Invalid code',
                    description: "Could not verify OTP sent",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='main'>
            <Center>
                <Box bg='white' rounded='md' w={{ base: 350, md: 480, xl: 480}} h={480} mt={130} pl={20} pt={5} pr={20} >
                    <Center>
                        <Stack spacing={2} alignItems='center'>
                            <Flex>
                                <Center>
                                    <Image src={Logo} alt='Swahilies' h={70} />
                                    <Heading as='h2' size='xl' fontFamily='Inter, sans-serif'>Pay</Heading>
                                </Center>
                            </Flex>
                            <Text fontSize={13} color='#9A9A9A' fontFamily='Inter, sans-serif' >Enter the verification code sent to {phone_number}</Text>
                            <FormControl>
                                <HStack mt={12} mb={6} justifyContent='center'>
                                    <PinInput
                                        type='number'
                                        // value={otp}
                                        
                                    >
                                        <PinInputField onChange={handleInputChange1} />
                                        <PinInputField onChange={handleInputChange2}  />
                                        <PinInputField onChange={handleInputChange3}  />
                                        <PinInputField onChange={handleInputChange4}  />
                                    </PinInput>
                                </HStack>
                                {
                                    isLoading ? (
                                        <Button
                                            bg='#F6A414'
                                            variant='solid'
                                            size='md'
                                            height='35px'
                                            width='325px'
                                            color='white'
                                            mt={6}
                                            mb={4}
                                            // onClick={onSubmit}
                                            isLoading
                                            loadingText='Submitting'
                                        >Proceed
                                        </Button>
                                    ) :
                                    (
                                    <Button
                                        bg='#F6A414'
                                        variant='solid'
                                        size='md'
                                        height='35px'
                                        width='325px'
                                        color='white'
                                        mt={6}
                                        mb={4}
                                        onClick={onSubmit}
                                    >Proceed
                                    </Button>
                                    )
                                }
                            </FormControl>
                            <Text as='b' fontSize={13} color='#9A9A9A' fontFamily='Inter, sans-serif' >Haven’t received code? Resend</Text>
                        </Stack>
                    </Center>
                </Box>
            </Center>
        </div>
        
    )
}

export default Otp