import * as React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { ChakraProvider } from '@chakra-ui/react'
import MainBody from './components/main';
import Login from './components/login';
import Otp from './components/otp';
import AuthGuard from './guards/AuthGuards';
import { AuthProvider } from './contexts/JWTContext';

const AppWrapper = () => {
  let routes = useRoutes([
    { path: "/", element: <Login /> },
    {
      path: "home",
      element:
        <AuthGuard>
          <MainBody />
        </AuthGuard>
    },
    { path: "otp", element: <Otp /> },
    // ...
  ]);
  return routes;
};


function App() {

  return (
    <CookiesProvider>
      <Router>
        <AuthProvider>
          <ChakraProvider>
            <AppWrapper />
          </ChakraProvider>
        </AuthProvider>
      </Router>
    </CookiesProvider>
  );
}

export default App;
