import PropTypes from 'prop-types';
import { useState } from 'react';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../components/login/index'
// components
import { Spinner, Center } from '@chakra-ui/react'
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({ children }) {
    const { isAuthenticated, isInitialized } = useAuth();
    
    const { pathname } = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);

    if (!isInitialized) {
        return (
            <Center>
                <Spinner
                    mt={100}
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='#F6A414'
                    size='xl'
                />
            </Center>
            
        )
    }

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
        }
        return <Login />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
}
