import React from 'react'
import Header from './Header'
import BodyCard from './BodyCard'
import BodyTable from './BodyTable'

function MainBody() {
    return (
        <div style={{ backgroundColor: '#EFEFEF', height: '100%' }}>
            <Header />
            <BodyCard />
            {/* <BodyTable /> */}
        </div>
    )
}

export default MainBody